// Here you can add other styles
.react-datepicker-wrapper {
  display: block;
  padding: 0;
  border: 0;
}
.upload-label {
  cursor: pointer;
  margin: 0;
  height: 37px;
}
.uploaded-name {
  font-size: 16px;
  text-align: center;
  margin: 0;
}
.upload-label input {
  display: none;
}

.upload-text {
  padding: 8px;
  color: white;
}

.upload-btn {
  background-color: $primary;
  border-width: 1px;
  border-radius: 4px;
}
span:hover {
  color: "white";
}

.mw-100 {
  min-width: 100px !important;
}
